import React from "react";

const Publication = ({
  year,
  img,
  title,
  authors,
  conference,
  summary,
  links,
}) => {
  return (
    <div className="pub-card">
      <img src={img} alt=""></img>

      <div className="info">
        <strong>{title}</strong>
        <div>{authors}</div>
        <div>{conference}</div>
        <div>{summary}</div>

        <div className="links">
        {links.code !== null && <CodeLink link={links.code} />}
        {links.page !== null && <PageLink link={links.page} />}
        {links.pdf !== null && <PdfLink link={links.pdf} />}
        {links.arxiv !== null && <ArxivLink link={links.arxiv} />}
      </div>
      </div>
    </div>
  );
};
export default Publication;

const CodeLink = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="code-link"
    >
      Code
    </a>
  );
};

const PageLink = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="page-link"
    >
      Page
    </a>
  );
};

const PdfLink = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="page-link"
    >
    PDF
    </a>
  );
};

const ArxivLink = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="page-link"
    >
    PDF
    </a>
  );
};