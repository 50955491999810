import React from "react"
import '../assets/css/main.scss'

const About = () => {
  return (
    <>
      <section id="about" class="about breakpoint">
        <div class="about-text">
          <img class="logo" alt="Threedle Logo" src="https://i.imgur.com/nKcNS2Q.png"></img>
          <h2><p>We are a computer graphics collective at UChicago led by <a href="https://people.cs.uchicago.edu/~ranahanocka/">Rana Hanocka</a>, pursuing innovation at the intersection of 3D and Deep Learning.</p></h2>
        </div>
      </section>
    </>
  )
}
export default About;