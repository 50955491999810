import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import "../assets/css/main.scss";

const BlogItem = ({ slug, date, title, description}) => {
  return(
    <div>
      <span>{date}</span>
      <Link to={slug}><h2>{title}</h2></Link>
      <p>{description}</p>
    </div>
  )
}

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {frontmatter: {slug: {regex: "/blog/"}}}
        sort: {order: DESC, fields: [frontmatter___date]}
        limit: 5
      ){
        edges {
          node {
            html
            frontmatter {
              slug
              date
              title
              description
            }
          }
        }
      }
    }
    `);

    return (
      <section id="blog" className="blog">
        <h3>Blog</h3>
        <div className="blog-grid">
          {(data.allMarkdownRemark.edges).map((e) => 
            {
              return(
                <BlogItem slug={e.node.frontmatter.slug} date={e.node.frontmatter.date} title={e.node.frontmatter.title} description={e.node.frontmatter.description} />
              )
            }
          )}
        </div>

        {/* <div className="more">
          <Link to="/archive"> see all... </Link>
        </div> */}
      </section>
    )
}

export default Blog