import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Publication from "./Publication"
import Aos from "aos"

const Research = () => {
	useEffect(() => {
		Aos.init({duration: 1200})
	})
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {frontmatter: {slug: {regex: "/research/"}}}
        sort: {fields: [frontmatter___date], order: DESC}
      ) {
      edges {
          node {
          frontmatter {
              slug
              title
			        img
              date
              authors
              conference
              links {
              code
              page
              pdf
              arxiv
              }
          }
          html
          }
        }
      }
    }
    `);

    return (
      <section id="research" className="breakpoint" data-aos="fade-up">
        <div className="research">
          <h3>Research</h3>
		  <p>Our research is focused on building artificial intelligence for <strong>3D</strong> data, spanning the fields of computer graphics, machine learning, and computer vision. <strong>D</strong>eep <strong>L</strong>earning, the most popular form of artificial intelligence, has unlocked remarkable success on structured data (such as text, images, and video), and we are interested in harnessing the potential of these techniques to enable effective operation on unstructured 3D geometric data. </p>
		  <p>We have developed a <a href="https://ranahanocka.github.io/MeshCNN/">convolutional neural network designed specifically for meshes</a>, and also explored how to learn from the internal data within a single shape (for <a href="https://ranahanocka.github.io/point2mesh/">surface reconstruction</a>, <a href="https://ranahanocka.github.io/geometric-textures/">geometric texture synthesis</a>, and <a href="https://galmetzer.github.io/self-sample/">point cloud consolidation</a>) - and are interested in broader applications related to these areas. Additional research directions that we are aiming to explore include: intertwining human and machine-based creativity to advance our capabilities in 3D shape modeling and animation; learning with less supervision, for example to extract patterns and relationships from large shape collections; and making 3D neural networks more interpretable and explainable. </p>
            <p>Follow our <a href="https://github.com/threedle">github organization</a> for updates on our latest research.</p>
          <div className="pub-grid breakpoint">
            {(data.allMarkdownRemark.edges).map((e) => 
              {
                return(
                  <Publication year={e.node.frontmatter.year} img={e.node.frontmatter.img} title={e.node.frontmatter.title} authors={e.node.frontmatter.authors} conference={e.node.frontmatter.conference} links={e.node.frontmatter.links}/>
                )
              }
            )}
          </div>
        </div>
      </section>
    )
}

export default Research
