import React from "react";

export const Person = ({ name, title, img, link }) => {
  return (
    <>
      <a class="person" href={link} id={title}>
        <span class="rotated-title">
          <p class="title">{title}</p>
        </span>
        <div class="img-wrap">
          <img src={img} alt=""></img>
          <h4>{name}</h4>
        </div>
      </a>
    </>
  );
};
