import * as React from "react"
import Layout from "../components/Layout"
import About from "../components/About"
import People from "../components/People"
import Research from "../components/Research"
import NewsGrid from "../components/NewsGrid"
import Courses from "../components/Courses"
import Blog from "../components/Blog"

export default function IndexPage() {
	
  return (
    <Layout>
        <About/>
        <People/>
        <NewsGrid/>
		    <Research/>
        <Courses/>
        <Blog/>
    </Layout>
  )
} 
