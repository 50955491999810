import React, { useEffect } from "react";
import "../assets/css/main.scss";
import { Person } from "./Person";
import Aos from "aos";
import PeopleData from "../content/people/people.yaml"

const People = () => {
  useEffect(() => {
    Aos.init({ duration: 1200 });
    let pi = Array.from(document.querySelectorAll("#principal investigator"));
    let postdocs = Array.from(document.querySelectorAll("#postdoc"));
    let phds = Array.from(document.querySelectorAll("#phd"));
    let masters = Array.from(document.querySelectorAll("#masters"));
    let researchers = Array.from(document.querySelectorAll("#researcher"));
    let undergrads = Array.from(document.querySelectorAll("#undergrad"));
    let highschool = Array.from(document.querySelectorAll("#high school"));

    let all = [pi, postdocs, phds, masters, researchers, undergrads, highschool]
    all.map((p, index) => {
      p.forEach(() => {
        let target = Math.floor(Math.random() * p.length - 1) + 1;
        let target2 = Math.floor(Math.random() * p.length - 1) + 1;
        p[target].before(p[target2]);
      });
    });

    
  });
  return (
    <>  
    <section id="people" className="people breakpoint" data-aos="fade-up">
      <h3>People</h3>
      <div class="container">
        {PeopleData.map((data, index) => {
        return data.title === 'principal investigator' ? <Person key={`content_item_${index}`} name={data.name} title={data.title} img={data.img} link={data.link}></Person> : null
        })}
        {PeopleData.map((data, index) => {
        return data.title === 'phd' ? <Person key={`content_item_${index}`} name={data.name} title={data.title} img={data.img} link={data.link}></Person> : null
        })}
        {PeopleData.map((data, index) => {
        return data.title === 'postdoc' ? <Person key={`content_item_${index}`} name={data.name} title={data.title} img={data.img} link={data.link}></Person> : null
        })}
        {PeopleData.map((data, index) => {
        return data.title === 'masters' ? <Person key={`content_item_${index}`} name={data.name} title={data.title} img={data.img} link={data.link}></Person> : null
        })}
        {PeopleData.map((data, index) => {
        return data.title === 'researcher' ? <Person key={`content_item_${index}`} name={data.name} title={data.title} img={data.img} link={data.link}></Person> : null
        })}
        {PeopleData.map((data, index) => {
        return data.title === 'undergrad' ? <Person key={`content_item_${index}`} name={data.name} title={data.title} img={data.img} link={data.link}></Person> : null
        })}
        {PeopleData.map((data, index) => {
        return data.title === 'high school' ? <Person key={`content_item_${index}`} name={data.name} title={data.title} img={data.img} link={data.link}></Person> : null
        })}
      </div>
    </section>
    </>
  );
};

export default People;
