import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Course = ({ slug, date, title, quarter, year, img }) => {
	return(
        <div className='course-card'>
          <img src={img} alt=""></img>
          <div className="info">
          <Link to={slug}>
            {title} ({quarter} {year})
          </Link>
          </div>
        </div>
    )
}

const Courses = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {frontmatter: {slug: {regex: "/courses/"}}}
        sort: {order: DESC, fields: [frontmatter___year]}
        limit: 5
      ){
        edges {
          node {
            html
            frontmatter {
              slug
              title
              quarter
              year
              img
            }
          }
        }
      }
    }
    `);

    return (
      <section id="courses" class="courses">
        <h3> Courses </h3>
        <div class="news-grid">
          {(data.allMarkdownRemark.edges).map((e) => 
            {
              return(
                <Course slug={e.node.frontmatter.slug} title={e.node.frontmatter.title} quarter={e.node.frontmatter.quarter} year= {e.node.frontmatter.year} img={e.node.frontmatter.img}/>
              )
            }
          )}
        </div>
      </section>
    )
}

export default Courses


