import React from 'react'
import { Link } from 'gatsby'

const News = ({ slug, date, title, description}) => {
  return(
    <div>
      <span>{date}</span>
      <Link to={slug}><h2>{title}</h2></Link>
      <p>{description}</p>
    </div>
  )
}

export default News