import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import News from "./News"

const NewsGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {frontmatter: {slug: {regex: "/news/"}}}
        sort: {order: DESC, fields: [frontmatter___date]}
        limit: 5
      ){
        edges {
          node {
            html
            frontmatter {
              slug
              date
              title
              description
            }
          }
        }
      }
    }
    `);

    return (
      <section id="news" className="news">
        <h3>News</h3>
        <div className="news-grid">
          {(data.allMarkdownRemark.edges).map((e) => 
            {
              return(
                <News slug={e.node.frontmatter.slug} date={e.node.frontmatter.date} title={e.node.frontmatter.title} description={e.node.frontmatter.description} />
              )
            }
          )}
        </div>

        <div className="more">
          <Link to="/archive"> see all... </Link>
        </div>
      </section>
    )
}

export default NewsGrid